ul {
    margin-bottom: 0px !important;
}

.template-body {
    background-color: #fff;
    color: #111;
    padding: 20px;
}

.template-name {
    font-size: 24px;
    font-family: 'Calibri';
    font-weight: 800;
}

.template-experience {
    font-size: 10px;
}
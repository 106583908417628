ul {
  margin-bottom: 0px !important;
}

.brand-color {
  color: #668e46;
}

body {
  font-family: Lato, sans-serif;
  font-size: 18px;
}

.section-header {
  font-size: 20px;
  font-family: "Roboto";
  color: #668e46;
}

.ted-template-content {
  font-size: 18px;
  line-height: 14px;
  text-align: justify;
}

.ted-hr {
  border: 1px solid #668e46 !important;
  margin: 0px !important;
  opacity: 1 !important;
}

.ted-template-body {
  background-color: #fff;
  color: #111;
  padding: 20px;
}

.header-content {
  display: flex;
}

.header-left {
  flex: 1;
}

.header-center {
  flex: 3;
  text-align: center;
}

.header-right {
  flex: 1;
}

.ted-template-candidate-details {
  font-size: 20px;
  font-family: "Calibri";
  font-weight: 800;
  color: #668e46;
  margin-bottom: 10px;
}

.ted-experience-headers {
  font-size: 18px;
  color: #668e46;
}

.ted-education-headers {
  font-size: 18px;
}

.ted-template-certification {
  font-size: 18px;
}

.ted-template-project {
  font-size: 18px;
}